/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ContentPageTemplate from "../../templates/content-page";
import KeyValuePair from "../../components/key-value-pair";
const MDXLayout = ContentPageTemplate;
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Berufliche Vita"), "\n", React.createElement(KeyValuePair, {
    one: "1979 - 1984",
    two: "Studium der Psychologie mit Abschluss Diplom"
  }), "\n", React.createElement(KeyValuePair, {
    one: "1981 - 1984",
    two: "Studium der Padagogik mit Abschluss Vordiplom"
  }), "\n", React.createElement(KeyValuePair, {
    one: "2005",
    two: "Dissertation an der Philosophischen Fakultat der Universitat Dusseldorf"
  }), "\n", React.createElement(KeyValuePair, {
    one: "1984 - 1992",
    two: "Selbstandige Arbeit in Arztpraxen in Opladen und Dusseldorf"
  }), "\n", React.createElement(KeyValuePair, {
    one: "1992",
    two: "Niederlassung in eigener Praxis mit allen Fachkunden, Einzel- und Gruppensettings"
  }), "\n", React.createElement(_components.h3, null, "Mitgliedschaften"), "\n", React.createElement(KeyValuePair, {
    one: "DGIP",
    two: "Deutsche Gesellschaft fur Individualtherapie"
  }), "\n", React.createElement(KeyValuePair, {
    one: "DPtV",
    two: "Deutsche Psychotherapeuten Vereinigung"
  }), "\n", React.createElement(KeyValuePair, {
    one: "PTK-NRW",
    two: "Psychotherapeutenkammer NRW"
  }), "\n", React.createElement(_components.h3, null, "Fachkunden und Zusatzweiterbildungen"), "\n", React.createElement(KeyValuePair, {
    one: "1992",
    two: "Verhaltenstherapie"
  }), "\n", React.createElement(KeyValuePair, {
    one: "1999",
    two: "Psychoanalyse"
  }), "\n", React.createElement(KeyValuePair, {
    one: "1999",
    two: "Tiefenpsychologisch fundierte Psychotherapie"
  }), "\n", React.createElement(KeyValuePair, {
    one: "2001",
    two: "Approbation als Psychologischer Psychotherapeut und Kinder- und Jugendlichenpsychotherapeut"
  }), "\n", React.createElement(KeyValuePair, {
    one: "2004",
    two: "Supervisor und Lehrtherapeut fur VT"
  }), "\n", React.createElement(KeyValuePair, {
    one: "2005",
    two: "Supervisor und Lehrtherapeut fur Psychoanalyse"
  }));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
